/* eslint-disable react/display-name */
import { Button, IconButton, makeStyles, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import DataTable from './components/DataTable';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { ModalContext } from './components/SimpleModal';
import { getExpos, saveExpo, deleteExpo } from '../../utils/dbRequests';
import Images from './components/ImageUploader';
import { Link } from '@reach/router';
import ExpoEdit from './EditExposition';
import DeleteModal from './components/DeleteModal';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,

    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'flex-start',
  },
  button: {
    maxWidth: 200,
  },
}));

export default function Expositions({ db, storage }) {
  const classes = useStyles();
  const handleModal = useContext(ModalContext);
  const [artists, setExpos] = useState([]);
  const [artCategories, setArtCategories] = useState([]);

  const init = async () => {
    refresh();
  };

  useEffect(() => {
    init();
  }, []);

  const openGallery = (expo) => {
    handleModal(
      <Images
        path={`${expo.id}/photos`}
        images={expo.photos}
        onChange={(images) => onGalleryChange({ ...expo, photos: images })}
        title="Exposition Photos"
        storage={storage}
      />
    );
  };

  const openCoverPhoto = (expo) => {
    handleModal(
      <Images
        path={`${expo.id.replace(' ', '_')}/coverPhoto`}
        isMultiple={false}
        images={[expo.coverPhoto]}
        onChange={(image) => onGalleryChange({ ...expo, coverPhoto: image })}
        title="Cover Photo"
        storage={storage}
      />
    );
  };

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      width: 250,
      renderCell: (params) => (
        <>
          <Button variant="text" size="small">
            {params.value}
          </Button>
        </>
      ),
    },
    {
      field: 'coverPhoto',
      headerName: 'Cover Photo',
      sortable: false,
      width: 200,
      renderCell: (params) => (
        <strong>
          <IconButton
            onClick={() => openCoverPhoto(params.row)}
            variant="contained"
            color="primary"
            size="small"
          >
            {params.value !== '' ? (
              <img
                width="40"
                style={{ objectFit: 'cover' }}
                src={params.value}
              />
            ) : (
              'Change'
            )}
          </IconButton>
        </strong>
      ),
    },
    {
      field: 'photos',
      headerName: 'Gallery',
      sortable: false,
      width: 120,
      renderCell: (params) => (
        <Button
          onClick={() => openGallery(params.row)}
          variant="outlined"
          size="small"
        >
          {params.value.length} Photos
        </Button>
      ),
    },
    {
      field: 'date',
      headerName: 'Date',
      sortable: false,
      width: 200,
    },
    {
      field: 'edit',
      headerName: 'Edit',
      sortable: false,
      width: 100,
      renderCell: (params) => (
        <IconButton
          variant="contained"
          color="primary"
          size="small"
          onClick={() => openAdd(artists[params.value])}
          style={{ marginLeft: 16 }}
        >
          <EditIcon />
        </IconButton>
      ),
    },
    {
      field: 'id',
      headerName: 'Delete',
      sortabfle: false,
      width: 100,
      renderCell: (params) => (
        <IconButton
          variant="contained"
          color="primary"
          size="small"
          onClick={() =>
            handleModal(
              <DeleteModal
                onCancel={handleModal}
                onSave={() => {
                  onDelete(params.value);
                  handleModal();
                }}
              />
            )
          }
          style={{ marginLeft: 16 }}
        >
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];
  const refresh = async () => {
    let processed = await getExpos(db);
    console.log(processed);
    processed = processed.map((artist, index) => {
      return { ...artist, edit: index };
    });
    setExpos(processed);
  };

  const onSave = async (info) => {
    info.date = new Date(info.date).toISOString();
    await saveExpo(db, info);
    handleModal();
    refresh();
  };

  const onGalleryChange = async (info) => {
    await saveExpo(db, info);
    refresh();
  };

  const onDelete = async (id) => {
    let res = await deleteExpo(db, id);
    refresh();
  };

  const openAdd = (expo) => {
    handleModal(
      <ExpoEdit expo={expo} onSave={onSave} onCancel={() => handleModal()} />
    );
  };

  return (
    <div>
      <Typography variant="h3">Expos</Typography>

      <div className={classes.root}>
        <Button
          onClick={() => openAdd()}
          className={classes.button}
          startIcon={<PersonAddIcon />}
          variant="outlined"
        >
          Add Expo
        </Button>
        <DataTable rows={artists} columns={columns} />
      </div>
    </div>
  );
}
