import nodemailer from 'nodemailer';

const mailTransport = nodemailer.createTransport({
  service: 'gmail',
  auth: {
    user: 'galeriagatotulipan@gmail.com',
    pass: 'ulmfwsdwxcafbxgz',
  },
});

export default async function sendMail(data) {
  try {
    let info = await mailTransport.sendMail({
      from: 'emailer@galeriaelgatotulipan.com', // sender address
      to: 'galeriagatotulipan@gmail.com', // list of receivers
      subject: 'Mensaje nuevo', // Subject line
      text: `Datos del contacto: nombre: ${data.name},correo: ${data.email},cel: ${data.cel}`,
      html: `<div>
                <h1>Nombre: ${data.name}</h1>
                <h3>Correo: ${data.email}</h3>
                <h3>Celular: ${data.phone}</h3>
                <h3>Asunto: ${data.subject}</h3>
                <p>Asunto: ${data.message}</p>

            </div>`, // html body
    });
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
}
