export const mapWorksFromArtist = (artists) => {
  let artworks = [];
  let names = [];
  artists.forEach((artist) => {
    let works = artist.artworks.map((work, index) => ({
      ...work,
      index,
      artist: artist.id,
      artistName: artist.name,
    }));
    artworks = artworks.concat(works);
    names.push({ name: artist.name, id: artist.id });
  });
  return [artworks, names];
};

export const shuffleArray = (array) => {
  for (var i = array.length - 1; i > 0; i--) {
    var j = Math.floor(Math.random() * (i + 1));
    var temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
  return array;
};
