import React from 'react';
import image from '../../assets/images/inicio2-min.jpg';
import ArtworksBar from './ArtworksBar';
import './Home.scss';

export default function About() {
  return (
    <div className="about">
      <h1>QUIÉNES SOMOS</h1>
      <div>
        <p>
          El Gato Tulipan nace como una asociación cultural en 2009 formada por
          artistas de diferentes nacionalidades unidos por un mismo objetivo:
          crear nuevos espacios artísticos itinerantes donde el arte logre
          derribar la valla de la exclusión social a través de diferentes
          manifestaciones artísticas. Entre los años 2009 y 2015 la asociación
          ha realizado exhibiciones colectivas de gran formato, talleres e
          intervenciones artísticas en diferentes espacios y con diferentes
          instituciones educativas.
        </p>
        <p>
          En 2016 se inauguró el Centro Cultural El Gato Tulipán en el distrito
          de Barranco, bajo la dirección de Patricio Crespo y Pilar Casaleiz,
          con el objetivo de crear un espacio dedicado a la promoción, difusión
          y exhibición de proyectos culturales, principalmente en la música y el
          arte visual, acogiendo distintas manifestaciones artísticas y
          promoviendo el acceso a la ciudadanía a una oferta cultural permanente
          y diversa, buscando también llegar a nuevos públicos y propiciar un
          encuentro entre agentes culturales.
        </p>
        <p>
          Desde entonces, se han realizado más de 15 exhibiciones entre
          individuales y colectivas, dando cabida a más de 20 artistas
          nacionales e internacionales, de diferentes disciplinas como la
          escultura, el Street Art, fotografía, cerámica, pintura o performance.
        </p>
      </div>
      <img
        className="section-image"
        style={{ maxWidth: 1000 }}
        src={image}
        alt="nosotros"
      />
    </div>
  );
}
