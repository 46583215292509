import { Button } from '@material-ui/core';
import { Link } from '@reach/router';
import React, { useContext, useEffect, useState } from 'react';
import ReactImageGallery from 'react-image-gallery';
import ContactForm from '../../components/ContactForm';
import { getArtist } from '../../utils/dbRequests';
import SimpleModal, { ModalContext } from '../Admin/components/SimpleModal';
import './Artwork.scss';
import ArtworksBar from './ArtworksBar';
import LongString from './LongString';

export default function Artwork({ db, artistId, workIndex }) {
  const [photos, setPhotos] = useState([]);
  const [artwork, setArtwork] = useState({});
  const [artist, setArtist] = useState({ artworks: [] });
  const handleModal = useContext(ModalContext);

  const init = async () => {
    const artist = await getArtist(db, artistId);
    const work = artist.artworks[workIndex];
    artist.artworks[workIndex].hide = true;
    setArtist(artist);
    setArtwork(work);
    setPhotos(
      work.photos.map((url) => ({
        original: url,
        thumbnail: url,
      }))
    );
  };

  useEffect(() => {
    init();
  }, [artistId, workIndex]);
  return (
    <>
      <div className="artwork-container">
        <ReactImageGallery
          additionalClass="work-gallery"
          showPlayButton={photos.length > 1}
          showThumbnails={photos.length > 1}
          thumbnailPosition="right"
          items={photos}
        />
        <div className="details">
          <div className="details-text">
            <h2>{artwork.title}</h2>
            <h3>{artist.name}</h3>
            <h3>${artwork.price}</h3>
            <p>Técnica: {artwork.technique}</p>
            <p>Dimensión: {artwork.dimensions}</p>
            {artwork.description !== '' && (
              <LongString
                className="description"
                string={artwork.description}
              />
            )}
            <Button
              onClick={() => {
                handleModal(
                  <ContactForm
                    db={db}
                    subject={
                      'Me interesa la obra - ' +
                      artwork.title +
                      ' - ' +
                      artwork.id
                    }
                    hiddenAttachment={artwork.photos[0]}
                    onSend={handleModal}
                  />
                );
              }}
              style={{ margin: '50px 0' }}
              color="primary"
              variant="contained"
            >
              Contáctanos
            </Button>
          </div>
        </div>
      </div>
      <div
        style={{
          padding: '50px 0',
          backgroundColor: 'rgba(211, 211, 211, 0.41)',
        }}
      >
        <h2>Mas obras de {artist.name}</h2>
        {artist.artworks.length > 0 && (
          <>
            <ArtworksBar artists={[artist]} />
            <Link to={`/obras/${artist.id}`}>
              <Button variant="contained" color="primary">
                Ver más
              </Button>
            </Link>
          </>
        )}
      </div>
    </>
  );
}
