import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import { Button, Slider, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '50px 0',
  },
  checksContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  formControl: {
    margin: theme.spacing(3),
  },
  slider: {
    width: '90%',
    margin: '0 auto',
  },
  formLabel: {
    minHeight: 50,
  },
}));

function valuetext(value) {
  return `${value}$`;
}

export default function Filter({
  categories = [],
  artists = [],
  onChange = () => {},
  initial = {},
}) {
  const classes = useStyles();
  const [artistsState, setArtistsState] = useState({});
  const [categoriesState, setCategoriesState] = useState({});
  const [range, setRange] = useState([0, 5000]);

  useEffect(() => {
    setArtistsState(initial.artists);
    setCategoriesState(initial.categories);
    setRange(initial.range);
  }, []);

  const handleCategoriesChange = (event) => {
    setCategoriesState((s) => ({
      ...s,
      [event.target.name]: event.target.checked,
    }));
  };

  const handleArtistsChange = (event) => {
    setArtistsState((s) => ({
      ...s,
      [event.target.name]: event.target.checked,
    }));
  };

  const handlePriceChange = (e, value) => {
    setRange(value);
  };

  const onSave = () => {
    let header = {
      categories: [],
      artists: [],
    };
    Object.keys(categoriesState).forEach((cat) => {
      if (categoriesState[cat]) {
        return header.categories.push(cat);
      }
    });
    Object.keys(artistsState).forEach((id) => {
      if (artistsState[id]) {
        artists.forEach((artist) => {
          if (artist.id === id) {
            header.artists.push(artist.name);
          }
        });
      }
    });
    header.range = `$${range[0]}-$${range[1]}+`;
    header.artists = header.artists.toString();
    header.categories = header.categories.toString();
    onChange({
      header,
      categories: categoriesState,
      artists: artistsState,
      range,
    });
  };

  return (
    <div className={classes.root}>
      <div className={classes.slider}>
        <Typography id="price-range-slider" gutterBottom>
          Por precio 0 - 5000+ (USD)
        </Typography>
        <Slider
          min={0}
          max={5000}
          step={50}
          value={range}
          onChange={handlePriceChange}
          valueLabelDisplay="auto"
          aria-labelledby="range-slider"
          getAriaValueText={valuetext}
        />
      </div>
      <div className={classes.checksContainer}>
        <FormControl component="fieldset" className={classes.formControl}>
          <FormLabel className={classes.formLabel} component="legend">
            Por tipo de arte
          </FormLabel>
          <FormGroup>
            {categories.map((cat) => {
              return (
                <FormControlLabel
                  key={cat.value}
                  control={
                    <Checkbox
                      checked={categoriesState[cat.value] || false}
                      color="secondary"
                      onChange={handleCategoriesChange}
                      name={cat.value}
                    />
                  }
                  label={cat.label}
                />
              );
            })}
          </FormGroup>
        </FormControl>
        <FormControl component="fieldset" className={classes.formControl}>
          <FormLabel className={classes.formLabel} component="legend">
            Por artista
          </FormLabel>
          <FormGroup>
            {artists.map((artist) => {
              return (
                <FormControlLabel
                  key={artist.id}
                  control={
                    <Checkbox
                      color="secondary"
                      checked={artistsState[artist.id] || false}
                      onChange={handleArtistsChange}
                      name={artist.id}
                    />
                  }
                  label={artist.name}
                />
              );
            })}
          </FormGroup>
        </FormControl>
      </div>

      <Button
        onClick={onSave}
        className={classes.button}
        variant="contained"
        color="primary"
      >
        Filtrar
      </Button>
    </div>
  );
}
