import React from 'react';
import InstagramIcon from '@material-ui/icons/Instagram';

export default function Footer() {
  return (
    <div style={{ margin: '50px 0' }}>
      <div style={{ margin: '20px 0' }}>
        <a href="https://www.instagram.com/gatotulipan/">
          <InstagramIcon size />
        </a>
      </div>
      ©2021 por Galería de Arte Gato Tulipán.
    </div>
  );
}
