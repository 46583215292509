import { Button } from '@material-ui/core';
import { Link } from '@reach/router';
import React, { useEffect, useState } from 'react';
import { mapWorksFromArtist, shuffleArray } from '../../utils/util';
import './Artworks.scss';

export default function ArtworksBar({ artists }) {
  const [works, setWorks] = useState([]);

  useEffect(() => {
    let [artworks, names] = mapWorksFromArtist(artists);
    artworks = shuffleArray(artworks);
    artworks = artworks.filter((work) => !work.hide);
    let shuffled = artworks.slice(0, 4);
    setWorks(shuffled);
  }, [artists]);
  return (
    <div>
      <div
        style={{ maxWidth: '100%', overflow: 'hidden' }}
        className="pieces-container"
      >
        {works.map((work) => {
          return <ArtworkPreview key={work.artist + work.id} work={work} />;
        })}
      </div>
    </div>
  );
}

const ArtworkPreview = ({ work }) => {
  return (
    <Link to={`/obras/${work.artist}/${work.index}`}>
      <img
        className="piece-preview"
        key={work.id}
        height="300px"
        src={work.photos[0]}
        alt=""
      />
      <h4>{work.title}</h4>
      <p>{work.artistName}</p>
    </Link>
  );
};
