export const getCategories = async (db) => {
  try {
    const doc = await db.collection('generalData').doc('artCategories').get();

    if (doc.exists) {
      const categories = doc
        .data()
        .data.map((cat) => ({ value: cat, label: cat }));
      return categories;
    } else {
      console.log('No such document!');
      return [];
    }
  } catch (error) {
    throw Error(error);
  }
};

export const getArtist = async (db, id) => {
  try {
    const doc = await db.collection('artists').doc(id).get();
    return doc.data();
  } catch (error) {
    throw Error(error);
  }
};

export const getArtists = async (db) => {
  try {
    const snapshot = await db.collection('artists').get();
    const array = snapshot.docs.map((artist) => {
      const processed = artist.data();
      processed.id = artist.id;
      return processed;
    });
    return array;
  } catch (error) {
    throw Error(error);
  }
};

export const sendMessage = async (db, info) => {
  if (!info.subject) delete info.subject;
  try {
    await db
      .collection('contact_info')
      .doc()
      .set({
        ...info,
        date_time: new Date().toISOString(),
      });
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const saveArtist = async (db, artist) => {
  const res = await db.collection('artists').doc(artist.id).set(artist);
};

export const deleteArtist = async (db, artistId) => {
  const res = await db.collection('artists').doc(artistId).delete();
};

export const deleteExpo = async (db, expoId) => {
  const res = await db.collection('expositions').doc(expoId).delete();
};

export const getHours = async (db) => {
  const doc = await db.collection('generalData').doc('openingHours').get();
  return doc.data().data;
};

export const getExpos = async (db) => {
  try {
    const snapshot = await db.collection('expositions').get();
    return snapshot.docs.map((doc) => doc.data());
  } catch (error) {
    throw Error(error);
  }
};

export const saveExpo = async (db, expo) => {
  const ref = await db.collection('expositions').doc(expo.id);
  expo.id = ref.id;
  ref.set(expo);
};
