import { Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

export default function LongString({ string = '', className }) {
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    setShowMore(false);
  }, [string]);

  if (string.length < 400 || showMore)
    return <p className={className}>{string}</p>;

  return (
    <>
      <p className={className}>{string.substring(0, 400)}...</p>
      <Button
        style={{ textTransform: 'capitalize' }}
        variant="text"
        onClick={() => setShowMore((s) => !s)}
      >
        Lee mas
      </Button>
    </>
  );
}
