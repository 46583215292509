import React, { useEffect, useState } from 'react';
import { SRLWrapper } from 'simple-react-lightbox';
import './Artist.scss';
import ReactHtmlParser from 'react-html-parser';
import ArtworksBar from './ArtworksBar';
import { Button, IconButton } from '@material-ui/core';
import { Link } from '@reach/router';
import { Instagram } from '@material-ui/icons';

export default function Artist({ id, artists }) {
  const [artist, setArtist] = useState();

  useEffect(() => {
    let selectedArtist = artists.filter((art) => art.id === id);
    setArtist(selectedArtist[0]);
  }, [artists, id]);

  if (!artist) return <div>Loading</div>;

  console.log(artist);

  return (
    <div>
      <div className="artist-header">
        <img width="100%" src={artist.bioPhoto} alt="" />
        <div className="artist-header-text">
          <h1 className="artist-name">{artist.name}</h1>
          <h3>{artist.artCategory.toString()}</h3>
        </div>
      </div>
      <div className="artist-bio-container">
        {ReactHtmlParser(artist.bio)}
        <a target="_blank" rel="noreferrer" href={artist.links}>
          <IconButton size="medium">
            <Instagram />
          </IconButton>
        </a>
      </div>
      {artist.artworks.length > 0 && (
        <div
          style={{
            padding: '50px 0',
            backgroundColor: 'rgba(211, 211, 211, 0.41)',
          }}
        >
          <h2>Obras de {artist.name}</h2>

          <ArtworksBar artists={[artist]} />
          <Link to={`/obras/${artist.id}`}>
            <Button variant="contained" color="primary">
              Ver más
            </Button>
          </Link>
        </div>
      )}
      <h2 style={{ margin: '50px 0' }}>Galería de artista</h2>
      <SRLWrapper>
        <div className="artist-gallery">
          {artist.gallery.map((img) => {
            return (
              <a key={img} href={img}>
                <img src={img} />
              </a>
            );
          })}
        </div>
      </SRLWrapper>
    </div>
  );
}
