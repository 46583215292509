import { Button } from '@material-ui/core';
import { Link } from '@reach/router';
import React from 'react';
import image1 from '../../assets/images/inicio1-min.jpg';
import image2 from '../../assets/images/inicio2-min.jpg';
import ArtworksBar from './ArtworksBar';
import './Home.scss';

export default function Home({ hours, artists }) {
  return (
    <div>
      <img className="section-image" src={image1} alt="" />
      <section
        style={{ backgroundColor: '#d3d3d369', margin: 0, padding: '50px 0' }}
      >
        <h1 style={{ margin: 0 }}>Galería de Arte en Línea</h1>
        <h3 style={{ marginBottom: 50 }}>Nuestras obras disponibles</h3>
        <ArtworksBar artists={artists} />
        <Link to="/obras">
          <Button variant="contained" color="primary">
            Ver todas las obras
          </Button>
        </Link>
      </section>
      <section className="section-row">
        <div className="hours-container">
          <h1>Horario</h1>
          {hours.split('/').map((text) => (
            <p key={text}>{text}</p>
          ))}
        </div>
        <div className="vertical-line" />
        <div>
          <h1>Dirección</h1>
          <p>Bajada de Baños 350, Barranco - Lima Perú</p>
        </div>
      </section>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d21068.09285635611!2d-77.03143378914295!3d-12.147144543686625!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105b7ed74155255%3A0xad03984b57b85a24!2sEl%20Gato%20Tulip%C3%A1n!5e0!3m2!1sen!2spe!4v1612993084095!5m2!1sen!2spe"
        width="100%"
        height="450"
        frameBorder="0"
        style={{ border: 0 }}
        allowFullScreen=""
        aria-hidden="false"
        tabIndex="0"
      />
    </div>
  );
}
