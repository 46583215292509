/* eslint-disable react/display-name */
import { Button, IconButton, makeStyles, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import DataTable from './components/DataTable';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ArtistEdit from './ArtistEdit';
import { ModalContext } from './components/SimpleModal';
import {
  getArtists,
  getCategories,
  saveArtist,
  deleteArtist,
} from '../../utils/dbRequests';
import Images from './components/ImageUploader';
import { Link } from '@reach/router';
import DeleteModal from './components/DeleteModal';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,

    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'flex-start',
  },
  button: {
    maxWidth: 200,
  },
}));

export default function Artists({ db, storage }) {
  const classes = useStyles();
  const handleModal = useContext(ModalContext);
  const [artists, setArtists] = useState([]);
  const [artCategories, setArtCategories] = useState([]);

  const init = async () => {
    setArtCategories(await getCategories(db));
    refresh();
  };

  useEffect(() => {
    init();
  }, []);

  const openGallery = (artist) => {
    handleModal(
      <Images
        path={`${artist.name.toLowerCase().replace(' ', '_')}_${
          artist.id
        }/gallery`}
        images={artist.gallery}
        onChange={(images) => onGalleryChange({ ...artist, gallery: images })}
        title="Artist Gallery"
        storage={storage}
      />
    );
  };

  const openBioPhoto = (artist) => {
    handleModal(
      <Images
        path={`${artist.name.toLowerCase().replace(' ', '_')}_${
          artist.id
        }/bioPhoto`}
        isMultiple={false}
        images={[artist.bioPhoto]}
        onChange={(image) => onGalleryChange({ ...artist, bioPhoto: image })}
        title="Bio Photo"
        storage={storage}
      />
    );
  };

  const openProfilePhoto = (artist) => {
    handleModal(
      <Images
        path={`${artist.name.toLowerCase().replace(' ', '_')}_${
          artist.id
        }/profilePhoto`}
        isMultiple={false}
        images={[artist.profilePhoto]}
        onChange={(image) =>
          onGalleryChange({ ...artist, profilePhoto: image })
        }
        title="Profile Photo"
        storage={storage}
      />
    );
  };

  const columns = [
    {
      field: 'profilePhoto',
      headerName: 'Photo',
      sortable: false,
      width: 80,
      renderCell: (params) => (
        <strong>
          <IconButton
            onClick={() => openProfilePhoto(params.row)}
            variant="contained"
            color="primary"
            size="small"
          >
            <img width="40" style={{ objectFit: 'cover' }} src={params.value} />
          </IconButton>
        </strong>
      ),
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 250,
      renderCell: (params) => (
        <>
          <Button variant="text" size="small">
            {params.value}
          </Button>
        </>
      ),
    },
    {
      field: 'artCategory',
      headerName: 'Art Category',
      type: 'string',
      width: 180,
    },
    {
      field: 'bioPhoto',
      headerName: 'Bio Photo',
      sortable: false,
      width: 160,
      renderCell: (params) => (
        <Button
          onClick={() => openBioPhoto(params.row)}
          variant="outline"
          size="small"
        >
          <img height="45" width="45" src={params.value} />
          change
        </Button>
      ),
    },
    {
      field: 'links',
      headerName: 'Links',
      sortable: false,
      width: 170,
    },
    {
      field: 'gallery',
      headerName: 'Gallery',
      sortable: false,
      width: 120,
      renderCell: (params) => (
        <Button
          onClick={() => openGallery(params.row)}
          variant="outline"
          size="small"
        >
          {params.value.length} Photos
        </Button>
      ),
    },
    {
      field: 'artworks',
      headerName: 'Artworks',
      width: 120,
      renderCell: (params) => (
        <Link to={`/admin/artists/${params.row.id}`}>
          <Button variant="text" size="small">
            {params.value.length} Artworks
          </Button>
        </Link>
      ),
    },
    {
      field: 'edit',
      headerName: 'Edit',
      sortable: false,
      width: 100,
      renderCell: (params) => (
        <IconButton
          variant="contained"
          color="primary"
          size="small"
          onClick={() => openAdd(artists[params.value])}
          style={{ marginLeft: 16 }}
        >
          <EditIcon />
        </IconButton>
      ),
    },
    {
      field: 'id',
      headerName: 'Delete',
      sortable: false,
      width: 100,
      renderCell: (params) => (
        <IconButton
          variant="contained"
          color="primary"
          size="small"
          onClick={() =>
            handleModal(
              <DeleteModal
                onCancel={handleModal}
                onSave={() => {
                  onDelete(params.value);
                  handleModal();
                }}
              />
            )
          }
          style={{ marginLeft: 16 }}
        >
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];
  const refresh = async () => {
    let processed = await getArtists(db);
    processed = processed.map((artist, index) => {
      return { ...artist, edit: index };
    });
    setArtists(processed);
  };

  const onSave = async (info) => {
    await saveArtist(db, info);
    handleModal();
    refresh();
  };

  const onGalleryChange = async (info) => {
    await saveArtist(db, info);
    refresh();
  };

  const onDelete = async (id) => {
    let res = await deleteArtist(db, id);
    refresh();
  };

  const openAdd = (artist) => {
    handleModal(
      <ArtistEdit
        artCategories={artCategories}
        artist={artist}
        onSave={onSave}
        onCancel={() => handleModal()}
      />
    );
  };

  return (
    <div>
      <Typography variant="h3">Artists</Typography>

      <div className={classes.root}>
        <Button
          onClick={() => openAdd()}
          className={classes.button}
          startIcon={<PersonAddIcon />}
          variant="outlined"
        >
          Add Artist{' '}
        </Button>
        <DataTable rows={artists} columns={columns} />
      </div>
    </div>
  );
}
