import { Link } from '@reach/router';
import React from 'react';
import './Expositions.scss';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { useEffect } from 'react';
import { useState } from 'react';

export default function Expositions({ expos }) {
  const [sorted, setSorted] = useState([]);

  useEffect(() => {
    const processed = expos.sort((a, b) => {
      const aDate = new Date(a.date).getTime();
      const bDate = new Date(b.date).getTime();
      return bDate - aDate;
    });
    setSorted(processed);
  }, [expos]);

  return (
    <div className="expos-container" direction="row">
      {sorted.map((expo) => {
        return <ExpoPreview key={expo.id} expo={expo} />;
      })}
    </div>
  );
}

const ExpoPreview = ({ expo }) => {
  return (
    <div className="expo-container">
      <Link style={{ width: '100%' }} to={`/exposiciones/${expo.id}`}>
        <div className="preview-hover">Ver mas</div>
        <img className="bio-photo" src={expo.coverPhoto} alt="" />

        <div className="expo-preview-header">
          {/* <img className="profile-photo" src={expo.profilePhoto} alt="" /> */}
          <div className="text-container">
            <h3>{expo.name}</h3>

            <div className="row">
              <CalendarTodayIcon style={{ marginRight: 10 }} fontSize="small" />
              <p>{new Date(expo.date).toLocaleDateString()}</p>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};
