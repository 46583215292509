import { Router, globalHistory } from '@reach/router';
import React, { useEffect } from 'react';
import Navbar from '../../components/Navbar';
import Artists from './Artists';
import Contact from './Contact';
import Footer from './Footer';
import Home from './Home';
import {
  createMuiTheme,
  makeStyles,
  ThemeProvider,
} from '@material-ui/core/styles';
import Artist from './Artist';
import Artworks from './Artworks';
import SimpleReactLightbox from 'simple-react-lightbox';
import Artwork from './Artwork';
import Expositions from './Expositions';
import SimpleModal from '../Admin/components/SimpleModal';
import About from './About';
import Exposition from './Exposition';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#333',
    },
    secondary: {
      main: '#CA274F',
    },
  },
  typography: {
    fontFamily: `'Montserrat', sans-serif`,
    button: {
      fontWeight: 600,
    },
  },
});

export default function MainView({ data, db }) {
  useEffect(() => {
    globalHistory.listen(({ action }) => {
      if (action === 'PUSH') {
        window.scrollTo(0, 0);
      }
    });
  }, []);
  return (
    <>
      <Navbar />
      <ThemeProvider theme={theme}>
        <SimpleModal className="front-modal">
          <div className="main">
            <div className="main-content">
              <SimpleReactLightbox>
                <Router>
                  <Home
                    hours={data.openingHours}
                    artists={data.artists}
                    path="/inicio"
                  />
                  <About path="/nosotros" />
                  <Artists artists={data.artists} path="/artistas" />
                  <Artist artists={data.artists} path="/artistas/:id" />
                  <Artworks
                    artists={data.artists}
                    categories={data.categories}
                    path="/obras"
                  />
                  <Artworks
                    artists={data.artists}
                    categories={data.categories}
                    path="/obras/:artistId"
                  />
                  <Artwork db={db} path="/obras/:artistId/:workIndex" />

                  <Expositions expos={data.expos} path="/exposiciones" />
                  <Exposition expos={data.expos} path="/exposiciones/:id" />
                  <Contact db={db} path="/contacto" />
                </Router>
              </SimpleReactLightbox>
            </div>
            <div className="main-spacer" />
            <Footer />
          </div>
        </SimpleModal>
      </ThemeProvider>
    </>
  );
}
