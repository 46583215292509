import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Chip,
  FormControl,
  Grid,
  Input,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '80vw',
    maxWidth: 800,
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  input: {
    width: '100%',
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 200,
    // maxWidth: 300,
  },
}));

const initialArtwork = {
  photos: [],
  title: [],
  artCategory: [],
  technique: '',
  dimensions: '',
  description: '',
};

export default function ArtworkEdit({
  artwork,
  artCategories = [],
  onCancel,
  onSave,
}) {
  const classes = useStyles();
  const [isEdit, setIsEdit] = useState(artwork !== undefined);
  const [artworkInfo, setArtworkInfo] = useState(artwork || initialArtwork);

  // useEffect(() => {
  //   console.log(artworkInfo.artCatgory);
  //   let filteredCategories = artworkInfo.artCategory.filter(
  //     (cat) => artCategories.findIndex((artCat) => artCat.value === cat) !== -1
  //   );
  //   setArtworkInfo((s) => ({ ...s, artCategory: filteredCategories }));
  // }, []);

  const handleChange = (e) => {
    setArtworkInfo((s) => ({ ...s, [e.target.name]: e.target.value }));
  };

  return (
    <Grid className={classes.container} container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h4">
          {isEdit ? 'Edit artwork info' : 'Add artwork'}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <TextField
          className={classes.input}
          variant="outlined"
          placeholder="Title"
          value={artworkInfo.title || ''}
          label="Title"
          name="title"
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          name="price"
          className={classes.input}
          label="Price"
          value={artworkInfo.price || ''}
          onChange={handleChange}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={4}>
        <FormControl className={classes.formControl}>
          <InputLabel id="artCategory">Art type</InputLabel>
          <Select
            labelId="artCategory"
            id="artCategory"
            onChange={handleChange}
            name="artCategory"
            variant="outlined"
            value={artworkInfo.artCategory || ''}
          >
            {artCategories.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <TextField
          className={classes.input}
          variant="outlined"
          label="Technique"
          value={artworkInfo.technique}
          placeholder={`instagram.com, youtube.com`}
          name="technique"
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          className={classes.input}
          variant="outlined"
          value={artworkInfo.dimensions || ''}
          name="dimensions"
          label="Dimensions"
          onChange={handleChange}
          placeholder="Write Bio here"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          className={classes.input}
          variant="outlined"
          value={artworkInfo.description || ''}
          name="description"
          rows={8}
          multiline
          label="Description"
          onChange={handleChange}
          placeholder="Description of the piece"
        />
      </Grid>
      <Grid item xs={6}></Grid>
      <Grid item xs={3}>
        <Button
          onClick={onCancel}
          className={classes.input}
          variant="contained"
        >
          Cancel
        </Button>
      </Grid>
      <Grid item xs={3}>
        <Button
          onClick={() => onSave(artworkInfo, !isEdit)}
          className={classes.input}
          variant="contained"
          color="primary"
        >
          Save
        </Button>
      </Grid>
    </Grid>
  );
}

ArtworkEdit.propTypes = {
  artist: PropTypes.object,
};

ArtworkEdit.defaultProps = {
  artist: undefined,
};
