import { Button } from '@material-ui/core';
import { Link, navigate } from '@reach/router';
import React, { useContext, useEffect, useState } from 'react';
import { mapWorksFromArtist, shuffleArray } from '../../utils/util';
import './Artworks.scss';
import Filter from './Filter';
import SettingsIcon from '@material-ui/icons/Settings';
import { ModalContext } from '../Admin/components/SimpleModal';
import { Delete } from '@material-ui/icons';

export default function Artworks({ artists = [], categories = [], artistId }) {
  const handleModal = useContext(ModalContext);
  const [works, setWorks] = useState([]);
  const [filteredWorks, setFilteredWorks] = useState([]);
  const [filters, setFilters] = useState({});

  useEffect(() => {
    const [artworks, names] = mapWorksFromArtist(artists);
    setWorks(artworks);
  }, []);

  const deleteFilters = () => {
    if (artistId) {
      navigate('/obras');
    } else {
      setFilters({});
    }
  };

  useEffect(() => {
    if (filters.artists && filters.categories && filters.range) {
      let artistNone = true;
      let catNone = true;
      Object.keys(filters.artists).forEach((artist) => {
        if (filters.artists[artist]) {
          artistNone = false;
          return;
        }
      });
      Object.keys(filters.categories).forEach((cat) => {
        if (filters.categories[cat]) {
          catNone = false;
          return;
        }
      });
      let filtered = works.filter((work) => {
        let artist = true;
        let cat = true;
        let price = true;
        if (!artistNone) {
          if (filters.artists[work.artist] !== true) {
            artist = false;
          }
        }
        if (!catNone) {
          if (filters.categories[work.artCategory] !== true) {
            cat = false;
          }
        }
        if (work.price) {
          let workprice = Number(work.price);
          if (
            workprice < filters.range[0] ||
            (workprice > filters.range[1] && workprice < 1000)
          ) {
            price = false;
          }
        }

        if (price && artist && cat) {
          return true;
        }
        return false;
      });
      filtered = shuffleArray(filtered);
      setFilteredWorks(filtered);
    } else {
      let categoriesStateNew = {};
      categories.forEach((cat) => (categoriesStateNew[cat.value] = false));
      let artistsStateNew = {};
      artists.forEach((artist) => (artistsStateNew[artist.id] = false));
      let header = {};
      if (artistId) {
        artistsStateNew[artistId] = true;
        header.artists = artists.filter((artist) => artist.id === artistId);
        header.artists[0] = header.artists[0].name;
      } else {
        header = undefined;
      }
      setFilters({
        header,
        categories: categoriesStateNew,
        artists: artistsStateNew,
        range: [0, 5000],
      });
    }
  }, [filters]);

  const openFilter = () => {
    handleModal(
      <Filter
        categories={categories}
        artists={artists}
        initial={filters}
        onChange={(value) => {
          setFilters(value);
          handleModal();
        }}
      />
    );
  };

  return (
    <div>
      <div className="fliter-container">
        <div>
          <Button
            size="large"
            onClick={openFilter}
            startIcon={<SettingsIcon />}
            variant="contained"
            color="secondary"
          >
            Filtros
          </Button>
          {filters.header && (
            <Button
              size="large"
              style={{ marginLeft: 10 }}
              onClick={deleteFilters}
              variant="outlined"
              startIcon={<Delete />}
            >
              Eliminar filtros
            </Button>
          )}
        </div>

        {filters.header && (
          <div className="filter-chips">
            {filters.header.artists && (
              <p>Artistas: {filters.header.artists}</p>
            )}
            {filters.header.categories && (
              <p>Categorias: {filters.header.categories}</p>
            )}
            {filters.header.range && <p>Precio: {filters.header.range}</p>}
          </div>
        )}
      </div>
      <div className="pieces-container">
        {filteredWorks.length > 0 ? (
          filteredWorks.map((work) => {
            return <ArtworkPreview key={work.artist + work.id} work={work} />;
          })
        ) : (
          <div>
            <h2 style={{ marginBottom: 30 }}>No se encuentran obras</h2>
            <Button
              startIcon={<Delete />}
              onClick={deleteFilters}
              variant="outlined"
            >
              Eliminar filtros
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

const ArtworkPreview = ({ work }) => {
  return (
    <Link to={`/obras/${work.artist}/${work.index}`}>
      <img
        className="piece-preview"
        key={work.id}
        height="300px"
        src={work.photos[0]}
        alt=""
      />
      <h4>{work.title}</h4>
      <p>{work.artistName}</p>
    </Link>
  );
};
