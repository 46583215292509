import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Grid,
  makeStyles,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  //   KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import 'jodit';
import 'jodit/build/jodit.min.css';
import JoditEditor from 'jodit-react';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '80vw',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  input: {
    width: '100%',
  },
}));

const initialExpo = {
  coverPhoto: '',
  photos: [],
  name: '',
  date: new Date(),
  content: '',
};

export default function ExpoEdit({ expo, onCancel, onSave }) {
  const classes = useStyles();
  const [isEdit, setIsEdit] = useState(expo !== undefined);
  const [expoInfo, setExpoInfo] = useState(expo || initialExpo);

  const handleChange = (e) => {
    setExpoInfo((s) => ({ ...s, [e.target.name]: e.target.value }));
  };

  const handleDateChange = (date) => {
    setExpoInfo((s) => ({ ...s, date }));
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid className={classes.container} container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h4">
            {isEdit ? 'Edit artist info' : 'Add artist'}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <TextField
            className={classes.input}
            variant="outlined"
            placeholder="Name"
            value={expoInfo.name || ''}
            label="Name"
            name="name"
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={4}>
          <KeyboardDatePicker
            margin="normal"
            id="date-picker-dialog"
            format="MM/dd/yyyy"
            value={expoInfo.date}
            onChange={handleDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <JoditEditor
            value={expoInfo.content}
            onChange={(content) => setExpoInfo((s) => ({ ...s, content }))}
          />
        </Grid>
        <Grid item xs={6}></Grid>
        <Grid item xs={3}>
          <Button
            onClick={onCancel}
            className={classes.input}
            variant="contained"
          >
            Cancel
          </Button>
        </Grid>
        <Grid item xs={3}>
          <Button
            onClick={() => onSave(expoInfo)}
            className={classes.input}
            variant="contained"
            color="primary"
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  );
}

ExpoEdit.propTypes = {
  expo: PropTypes.object,
};

ExpoEdit.defaultProps = {
  expo: undefined,
};
