import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Chip,
  FormControl,
  Grid,
  Input,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import 'jodit';
import 'jodit/build/jodit.min.css';
import JoditEditor from 'jodit-react';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '80vw',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  input: {
    width: '100%',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
    // maxWidth: 300,
  },
}));

const initialArtist = {
  gallery: [],
  photos: [],
  artworks: [],
  artCategory: [],
  isGalleryEnabled: true,
  bioPhoto: '',
  profilePhoto:
    'https://www.nacdnet.org/wp-content/uploads/2016/06/person-placeholder.jpg',
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: '100%',
    },
  },
};

export default function ArtistEdit({
  artist,
  onCancel,
  onSave,
  artCategories,
}) {
  const classes = useStyles();
  const [isEdit, setIsEdit] = useState(artist !== undefined);
  const [artistInfo, setArtistInfo] = useState(artist || initialArtist);

  useEffect(() => {
    let filteredCategories = artistInfo.artCategory.filter(
      (cat) => artCategories.findIndex((artCat) => artCat.value === cat) !== -1
    );
    setArtistInfo((s) => ({ ...s, artCategory: filteredCategories }));
  }, []);

  const handleChange = (e) => {
    setArtistInfo((s) => ({ ...s, [e.target.name]: e.target.value }));
  };

  return (
    <Grid className={classes.container} container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h4">
          {isEdit ? 'Edit artist info' : 'Add artist'}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <TextField
          className={classes.input}
          variant="outlined"
          placeholder="Name"
          value={artistInfo.name || ''}
          label="Name"
          name="name"
          onChange={handleChange}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          className={classes.input}
          variant="outlined"
          label="Link"
          value={artistInfo.links}
          placeholder={`instagram.com, youtube.com`}
          name="links"
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl className={classes.formControl}>
          <InputLabel id="artCategory">Art Categories</InputLabel>
          <Select
            labelId="artCategory"
            id="artCategory"
            multiple
            onChange={(e) => {
              setArtistInfo((s) => ({ ...s, artCategory: e.target.value }));
            }}
            variant="outlined"
            value={artistInfo.artCategory || []}
            input={<Input id="select-multiple-chip" />}
            renderValue={(selected) => (
              <div className={classes.chips}>
                {selected.map((value) => (
                  <Chip key={value} label={value} className={classes.chip} />
                ))}
              </div>
            )}
            MenuProps={MenuProps}
          >
            {artCategories.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <JoditEditor
          value={artistInfo.bio}
          onChange={(bio) => setArtistInfo((s) => ({ ...s, bio }))}
        />
      </Grid>

      <Grid item xs={6}></Grid>
      <Grid item xs={3}>
        <Button
          onClick={onCancel}
          className={classes.input}
          variant="contained"
        >
          Cancel
        </Button>
      </Grid>
      <Grid item xs={3}>
        <Button
          onClick={() => onSave(artistInfo)}
          className={classes.input}
          variant="contained"
          color="primary"
        >
          Save
        </Button>
      </Grid>
    </Grid>
  );
}

ArtistEdit.propTypes = {
  artist: PropTypes.object,
};

ArtistEdit.defaultProps = {
  artist: undefined,
};
