const firebaseConfig = {
  apiKey: 'AIzaSyAjhT_aBGmWiJgnD4aa8e49ShxHfsfwyLc',
  authDomain: 'galeria-gato-tulipan.firebaseapp.com',
  projectId: 'galeria-gato-tulipan',
  storageBucket: 'galeria-gato-tulipan.appspot.com',
  messagingSenderId: '337325572776',
  appId: '1:337325572776:web:dd4fb69159f631ac9c789d',
  measurementId: 'G-NFK0ZCK0FR',
};

export default firebaseConfig;
