import React from 'react';
import ContactForm from '../../components/ContactForm';
import './Contact.scss';

export default function Contact({ db }) {
  return (
    <div className="contact">
      <div className="contact-text">
        <h1>DIRECCIÓN</h1>
        <p>Bajada de Baños 350, Barranco - Lima</p>
        <p>Perú</p>
        <a
          className="email"
          target="_blank"
          rel="noreferrer"
          href="mailto:galeriadeartegatotulipan@gmail.com"
        >
          galeriadeartegatotulipan@gmail.com
        </a>
      </div>
      <div className="form">
        <ContactForm db={db} />
      </div>
    </div>
  );
}
