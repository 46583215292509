import { Link } from '@reach/router';
import React, { useEffect, useState } from 'react';
import './Artists.scss';

function compare(a, b) {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
}
export default function Artists({ artists }) {
  const [processedArtists, setProcessedArtists] = useState([]);

  useEffect(() => {
    let sorted = artists.sort(compare);
    setProcessedArtists(sorted);
  }, [artists]);
  return (
    <div className="artists-container" direction="row">
      {processedArtists.map((artist) => {
        return <ArtistPreview key={artist.id} artist={artist} />;
      })}
    </div>
  );
}

const ArtistPreview = ({ artist }) => {
  return (
    <div className="artist-container">
      <Link to={`/artistas/${artist.id}`}>
        <div className="artist-preview-header">
          <img className="profile-photo" src={artist.profilePhoto} alt="" />
          <div className="text-container">
            <h2>{artist.name}</h2>

            <h4>{artist.artCategory}</h4>
          </div>
        </div>
        <div className="preview-hover">Ver perfil</div>
        <img className="bio-photo" src={artist.bioPhoto} alt="" />
      </Link>
    </div>
  );
};
