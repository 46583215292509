/* eslint-disable react/display-name */
import { Button, IconButton, makeStyles, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import DataTable from './components/DataTable';
import { getArtist, getCategories, saveArtist } from '../../utils/dbRequests';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ArtworksEdit from './ArtworkEdit';
import { ModalContext } from './components/SimpleModal';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Link } from '@reach/router';
import ImageUploader from './components/ImageUploader';
import DeleteModal from './components/DeleteModal';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,

    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'flex-start',
  },
  button: {
    maxWidth: 200,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    padding: '40px 0',
    justifyContent: 'space-between',
  },
  backButton: {
    marginRight: 40,
  },
}));
export default function ArtistArtworks({ id, db, storage }) {
  const [artist, setArtist] = useState();
  const classes = useStyles();
  const handleModal = useContext(ModalContext);
  const [artCategories, setArtCategories] = useState([]);

  const refresh = async () => {
    let processed = await getArtist(db, id);
    processed.id = id;
    processed.artworks = processed.artworks.map((artwork, index) => {
      return { ...artwork, edit: index, id: index };
    });
    setArtist(processed);
  };

  const init = async () => {
    setArtCategories(await getCategories(db));
    refresh();
  };

  useEffect(() => {
    init();
  }, []);

  if (!artist) return <div>Not an artist</div>;

  const onSave = async (artwork, isNew = true) => {
    if (isNew) {
      await saveArtist(db, {
        ...artist,
        artworks: [...artist.artworks, artwork],
      });
    } else {
      let newArtworks = [...artist.artworks];
      newArtworks[artwork.edit] = artwork;
      await saveArtist(db, {
        ...artist,
        artworks: newArtworks,
      });
    }

    handleModal();
    refresh();
  };

  const openAdd = (artwork) => {
    handleModal(
      <ArtworksEdit
        artwork={artwork}
        artCategories={artCategories}
        onSave={onSave}
        onCancel={() => handleModal()}
      />
    );
  };

  const openGallery = (artwork) => {
    handleModal(
      <ImageUploader
        path={`${artist.name.toLowerCase().replace(' ', '_')}_${
          artist.id
        }/artworks/${artwork.id}`}
        images={artist.artworks[artwork.id].photos}
        onChange={(images) => {
          const artworks = [...artist.artworks];
          artworks[artwork.id] = { ...artwork, photos: images };
          onGalleryChange({ ...artist, artworks });
        }}
        title="Artwork Photos"
        storage={storage}
      />
    );
  };

  const onGalleryChange = async (info) => {
    await saveArtist(db, info);
    refresh();
  };

  const onDelete = async (id) => {
    let artworks = artist.artworks.filter((artwork) => artwork.id !== id);
    await saveArtist(db, { ...artist, artworks });
    refresh();
  };

  const columns = [
    {
      field: 'title',
      headerName: 'Title',
      sortable: false,
      width: 150,
    },
    {
      field: 'price',
      headerName: 'Price',
      sortable: false,
      width: 150,
    },
    {
      field: 'technique',
      headerName: 'Tecnique',
      sortable: false,
      width: 150,
    },
    {
      field: 'dimensions',
      headerName: 'Dimensions',
      sortable: false,
      width: 150,
    },
    {
      field: 'artCategory',
      headerName: 'Art Type',
      sortable: false,
      width: 150,
    },
    {
      field: 'photos',
      headerName: 'Photos',
      sortable: false,
      width: 120,
      renderCell: (params) => (
        <Button
          onClick={() => openGallery(params.row)}
          variant="outlined"
          size="small"
        >
          {params.value.length} Photos
        </Button>
      ),
    },
    {
      field: 'edit',
      headerName: 'Edit',
      sortable: false,
      width: 100,
      renderCell: (params) => (
        <IconButton
          variant="contained"
          color="primary"
          size="small"
          onClick={() => openAdd(artist.artworks[params.value])}
          style={{ marginLeft: 16 }}
        >
          <EditIcon />
        </IconButton>
      ),
    },
    {
      field: 'id',
      headerName: 'Delete',
      sortable: false,
      width: 100,
      renderCell: (params) => (
        <IconButton
          variant="contained"
          color="primary"
          size="small"
          onClick={() =>
            handleModal(
              <DeleteModal
                onCancel={handleModal}
                onSave={() => {
                  onDelete(params.value);
                  handleModal();
                }}
              />
            )
          }
          style={{ marginLeft: 16 }}
        >
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <div>
      <div className={classes.header}>
        <Link classes={classes.backButton} to="/admin/artists">
          <Button variant="outlined" startIcon={<ArrowBackIcon />}>
            Back
          </Button>
        </Link>
        <Typography variant="h4">{artist.name}&apos;s artworks</Typography>
      </div>

      <div className={classes.root}>
        <Button
          onClick={() => openAdd()}
          className={classes.button}
          variant="outlined"
        >
          Add Artwork
        </Button>
        <DataTable rows={artist.artworks} columns={columns} />
      </div>
    </div>
  );
}
