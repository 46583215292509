import React, { useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { SRLWrapper } from 'simple-react-lightbox';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';

import './Exposition.scss';
export default function Exposition({ id, expos }) {
  const [expo, setExpo] = useState();

  useEffect(() => {
    setExpo(expos.filter((ex) => ex.id === id)[0]);
  }, []);

  if (!expo) return null;

  return (
    <div className="expo-single-container">
      <div className="expo-header">
        <img width="100%" src={expo.coverPhoto} alt="" />
      </div>
      <div className="content">
        <h1>{expo.name}</h1>
        <div className="row">
          <CalendarTodayIcon style={{ marginRight: 10 }} fontSize="small" />
          <p>{new Date(expo.date).toLocaleDateString()}</p>
        </div>
        <div>{ReactHtmlParser(expo.content)}</div>
      </div>
      <h2 style={{ margin: '50px 0' }}>Galería de exposición</h2>
      <SRLWrapper>
        <div
          style={{ width: '90%', margin: '0 auto' }}
          className="artist-gallery"
        >
          {expo.photos.map((img) => {
            return (
              <a key={img} href={img}>
                <img src={img} />
              </a>
            );
          })}
        </div>
      </SRLWrapper>
    </div>
  );
}
